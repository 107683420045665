export default {
  data() {
    return {
      mixinErrors: {},
      mixinErrorsMainMessage: null,
    }
  },

  methods: {
    setErrors(errors) {
      this.mixinErrors = errors;
    },

    setErrorsMainMessage(message) {
      this.mixinErrorsMainMessage = message;
    },

    setErrorsFromException(e) {
      if (typeof e.response === 'undefined'
          || typeof e.response.data === 'undefined'
          || typeof e.response.data.errors === 'undefined'
          || typeof e.response.status === 'undefined'
          || e.response.status !== 422
      ) {
        throw e;
      }

      this.setErrors(e.response.data.errors);

      if (typeof e.response.data.message !== 'undefined') {
        this.setErrorsMainMessage(e.response.data.message);
      }
    },

    clearErrors() {
      this.mixinErrors = {};
      this.mixinErrorsMainMessage = null;
    },

    hasError(key) {
      return typeof this.mixinErrors[key] !== 'undefined' && this.mixinErrors[key].length > 0;
    },

    hasErrorsMainMessage() {
      return this.getErrorsMainMessage() !== null;
    },

    getError(key) {
      if (!this.hasError(key)) {
        return null;
      }

      return this.mixinErrors[key][0];
    },

    getErrorsMainMessage() {
      return this.mixinErrorsMainMessage;
    },
  }
}
