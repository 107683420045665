import Vue from 'vue'
import VueTailwind from 'vue-tailwind'

import TInput from 'vue-tailwind/dist/t-input'
import TTextarea from 'vue-tailwind/dist/t-textarea'
import TRichSelect from 'vue-tailwind/dist/t-rich-select'
import TInputGroup from 'vue-tailwind/dist/t-input-group'
import TButton from 'vue-tailwind/dist/t-button'
import TDropdown from 'vue-tailwind/dist/t-dropdown'
import TCard from 'vue-tailwind/dist/t-card'
import TTag from 'vue-tailwind/dist/t-tag'
import TSelect from 'vue-tailwind/dist/t-select'
import TPagination from 'vue-tailwind/dist/t-pagination'
import TTable from 'vue-tailwind/dist/t-table'
import TDatepicker from 'vue-tailwind/dist/t-datepicker'
import TCheckbox from 'vue-tailwind/dist/t-checkbox'
import TToggle from 'vue-tailwind/dist/t-toggle'

const settings = {
  't-input-group': {
    component: TInputGroup,
    props: {
      fixedClasses: {
        wrapper: 'mb-4',
        label: 'mb-2 text-sm block text-blue-500',
        body: '',
        feedback: 'text-sm',
        description: 'text-sm'
      },
      classes: {
        wrapper: '',
        label: '',
        body: '',
        feedback: 'text-gray-500',
        description: 'text-gray-500'
      },
      variants: {
        error: {
          feedback: 'text-error',
        }
      },
    }
  },
  't-input': {
    component: TInput,
    props: {
      fixedClasses: 'block rounded-lg disabled:opacity-50 disabled:cursor-not-allowed focus:outline-none transition duration-150 ease-in-out text-black placeholder-blue-300 bg-white border border-blue-200 hover:border-blue-300 focus:border-blue-500',
      classes: 'px-5 h-10 w-full',
      variants: {
        singleDigit: 'px-0 text-center w-10',
        large: 'px-5 h-12',
        search: 'rounded-2xl pr-10 text-gray-900 h-10 pl-5 border-blue-500',
      }
    }
  },
  't-textarea': {
    component: TTextarea,
    props: {
      fixedClasses: 'block w-full rounded-lg disabled:opacity-50 disabled:cursor-not-allowed focus:outline-none transition duration-150 ease-in-out text-black placeholder-blue-300 bg-white border border-blue-200 hover:border-blue-300 focus:border-blue-500 resize-none',
      classes: 'p-5 h-24'
    },
    variants: {
      narrow: 'p-2',
    },
  },
  't-button': {
    component: TButton,
    props: {
      fixedClasses: 'disabled:opacity-50 disabled:cursor-not-allowed inline-flex justify-center items-center transition ease-in-out duration-150 px-5 rounded-lg p-2 text-base leading-snug text-md focus:outline-none',
      classes: 'bg-blue-500 hover:bg-blue-400 active:bg-blue-500 text-white',
      variants: {
        primary: 'bg-blue-900 border text-blue-300',
        secondary: 'bg-white border border-blue-300 text-blue-700',
        tertiary: 'bg-blue-100 text-gray-900',
        link: 'text-blue-900 underline',
        transparent: 'bg-transparent border border-blue-300 text-blue-700'
      }
    }
  },
  't-dropdown': {
    component: TDropdown,
    props: {
      fixedClasses: {
        dropdown: 'absolute border-none bg-white mt-3 shadow-2xl rounded-2xl',
      },
      classes: {
        wrapper: 'flex flex-col ',
        dropdownWrapper: 'relative z-10',
        dropdown: 'origin-top-right right-0 rounded shadow-sm w-56',
        enterClass: '',
        enterActiveClass: 'transition ease-out duration-100 transform opacity-0 scale-95',
        enterToClass: 'transform opacity-100 scale-100',
        leaveClass: 'transition ease-in transform opacity-100 scale-100',
        leaveActiveClass: '',
        leaveToClass: 'transform opacity-0 scale-95 duration-75',
      },
      variants: {
        user: {
          dropdown: 'origin-top-right right-0 w-64'
        },
        auto: {
          dropdown: 'origin-top-left left-0 w-auto'
        },
        bottomLeft: {
          dropdown: 'origin-bottom bottom-0 w-auto'
        },
        topLeft: {
          dropdown: 'origin-top-left top-0 left-0 w-auto'
        },
      }
    }
  },
  't-card': {
    component: TCard,
    props: {
      fixedClasses: {
        wrapper: 'rounded-lg p-4 md:p-8',
        header: 'mt-6 first:mt-0',
        body: 'mt-6 first:mt-0',
        footer: 'mt-6 first:mt-0'
      },
      classes: {
        wrapper: 'bg-white'
      }
    }
  },
  't-tag': {
    component: TTag,
    props: {
      variants: {
        heading: 'text-xl font-semibold text-gray-900',
        subheading: 'text-lg font-semibold text-black',
      }
    }
  },
  't-select': {
    component: TSelect,
    props: {
      wrapped: true,
      defaultClasses: {
        input: 'disabled:opacity-50 disabled:cursor-not-allowed focus:outline-none transition duration-150 ease-in-out'
      },
      classes: {
        input: 'pl-5 pr-7 h-10 block w-full rounded-lg text-gray-900 placeholder-blue-300 bg-white border border-blue-200 hover:border-blue-300 focus:border-blue-500',
        arrowWrapper: 'hidden pointer-events-none absolute inset-y-0 right-0 flex items-center px-2'
      },
      variants: {
        small: {
          input: 'text-sm pl-5 pr-7 h-10 block w-full rounded-lg text-gray-900 placeholder-blue-300 bg-white border border-blue-200 hover:border-blue-300 focus:border-blue-500'
        },
        withoutBorder: {
          input: 'pl-5 pr-9 h-10 block w-full rounded-lg text-gray-900 placeholder-blue-300 bg-white border border-blue-200 hover:border-blue-300 focus:border-blue-500',
        },
      }
    }
  },
  't-rich-select': {
    component: TRichSelect,
    props: {
      fixedClasses: {
        wrapper: 'relative',
        buttonWrapper: 'inline-block relative w-full',
        selectButton: 'w-full flex text-left justify-between items-center',
        selectButtonLabel: 'block truncate',
        selectButtonTagWrapper: 'flex flex-wrap overflow-hidden',
        selectButtonTag: 'rounded-lg block disabled:cursor-not-allowed disabled:opacity-50 duration-100 ease-in-out focus:border-blue-500 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50 shadow-sm text-sm text-white transition white-space-no max-w-full overflow-hidden flex items-center',
        selectButtonTagText: '',
        selectButtonTagDeleteButton: '-ml-1.5 h-full inline-flex items-center px-2 transition',
        selectButtonTagDeleteButtonIcon: 'w-3 h-3',
        selectButtonPlaceholder: 'block truncate',
        selectButtonIcon: 'flex-shrink-0 ml-1 h-4 w-4',
        selectButtonClearButton: 'flex flex-shrink-0 items-center justify-center absolute right-0 top-0',
        selectButtonClearIcon: 'h-3 w-3',
        dropdown: 'absolute w-full z-10',
        dropdownFeedback: '',
        loadingMoreResults: '',
        optionsList: 'overflow-auto',
        searchWrapper: 'inline-block w-full',
        searchBox: 'inline-block w-full',
        optgroup: '',
        option: 'cursor-pointer',
        disabledOption: 'opacity-50 cursor-not-allowed',
        highlightedOption: 'cursor-pointer',
        selectedOption: 'cursor-pointer',
        selectedHighlightedOption: 'cursor-pointer',
        optionContent: '',
        optionLabel: 'truncate block',
        selectedIcon: 'fill-current h-4 w-4',
        enterClass: '',
        enterActiveClass: '',
        enterToClass: '',
        leaveClass: '',
        leaveActiveClass: '',
        leaveToClass: ''
      },
      classes: {
        wrapper: '',
        buttonWrapper: '',
        selectButton: 'px-3 py-3 pr-5 text-black transition duration-100 ease-in-out bg-white border border-blue-200 rounded-lg shadow-sm focus:border-blue-500 focus:ring-2 focus:ring-blue-500 focus:outline-none focus:ring-opacity-50 disabled:opacity-50 disabled:cursor-not-allowed',
        selectButtonLabel: '',
        selectButtonTag: 'border m-0.5 mr-2 h-8 bg-blue-100 border-blue-200',
        selectButtonTagText: 'text-gray-900 px-3',
        selectButtonTagWrapper: '-mx-2 -my-2.5 py-1 pr-8',
        selectButtonTagDeleteButton: '-ml-1.5 h-full inline-flex items-center px-2 transition',
        selectButtonTagDeleteButtonIcon: 'text-gray-600',
        selectButtonPlaceholder: 'text-gray-400',
        selectButtonIcon: '',
        selectButtonClearButton: 'hover:bg-blue-100 text-gray-600 rounded transition duration-100 ease-in-out m-2 h-6 w-6',
        selectButtonClearIcon: '',
        dropdown: '-mt-1 bg-white border-b border-blue-200 border-l border-r rounded-b-xl shadow-sm overflow-hidden',
        dropdownFeedback: 'pb-2 px-3 text-gray-400 text-sm',
        loadingMoreResults: 'pb-2 px-3 text-gray-400 text-sm',
        optionsList: '',
        searchWrapper: 'p-2 placeholder-gray-400',
        searchBox: 'px-3 py-2 bg-gray-50 text-sm rounded-lg border text-gray-900 focus:outline-none focus:shadow-outline border-blue-500',
        optgroup: 'text-gray-400 uppercase text-xs py-1 px-2 font-semibold',
        option: 'text-gray-900',
        disabledOption: '',
        highlightedOption: 'bg-blue-100 text-gray-900',
        selectedOption: 'font-semibold bg-gray-100 font-semibold text-gray-900',
        selectedHighlightedOption: 'font-semibold bg-blue-100 font-semibold text-gray-900',
        optionContent: 'flex flex-row justify-start items-center px-3 py-2',
        optionLabel: '',
        selectedIcon: 'hidden',
        enterClass: '',
        enterActiveClass: 'opacity-0 transition ease-out duration-100',
        enterToClass: 'opacity-100',
        leaveClass: 'transition ease-in opacity-100',
        leaveActiveClass: '',
        leaveToClass: 'opacity-0 duration-75'
      },
      variants: {
        small: {
          wrapper: '',
          buttonWrapper: '',
          selectButton: 'px-2 py-0 pr-5 text-black transition duration-100 ease-in-out bg-white border border-blue-200 rounded-lg shadow-sm focus:border-blue-500 focus:ring-2 focus:ring-blue-500 focus:outline-none focus:ring-opacity-50 disabled:opacity-50 disabled:cursor-not-allowed',
          selectButtonLabel: ' py-2',
          selectButtonTag: 'bg-red-100 border-blue-200 m-0.5 mr-2',
          selectButtonTagText: 'text-gray-900 px-0',
          selectButtonTagWrapper: '-my-2.5 py-1 pr-2',
          selectButtonTagDeleteButton: '-ml-1.5 h-full inline-flex items-center px-2 transition',
          selectButtonTagDeleteButtonIcon: 'text-gray-600',
          selectButtonPlaceholder: 'text-gray-400 py-2',
          selectButtonIcon: '',
          selectButtonClearButton: 'hover:bg-blue-100 text-gray-600 rounded transition duration-100 ease-in-out m-1 h-6 w-6',
          selectButtonClearIcon: '',
          dropdown: '-mt-1 bg-white border-b border-blue-200 border-l border-r rounded-b-xl shadow-sm overflow-hidden',
          dropdownFeedback: 'pb-2 px-3 text-gray-400 text-sm',
          loadingMoreResults: 'pb-2 px-3 text-gray-400 text-sm',
          optionsList: '',
          searchWrapper: 'p-2 placeholder-gray-400',
          searchBox: 'px-3 py-2 bg-gray-50 text-sm rounded-lg border text-gray-900 focus:outline-none focus:shadow-outline border-blue-500',
          optgroup: 'text-gray-400 uppercase text-xs py-1 px-2 font-semibold',
          option: 'text-gray-900',
          disabledOption: '',
          highlightedOption: 'bg-blue-100 text-gray-900',
          selectedOption: 'font-semibold bg-gray-100 font-semibold text-gray-900',
          selectedHighlightedOption: 'font-semibold bg-blue-100 font-semibold text-gray-900',
          optionContent: 'flex flex-row justify-start items-center px-3 py-0',
          optionLabel: 'py-2',
          selectedIcon: 'hidden',
          enterClass: '',
          enterActiveClass: 'opacity-0 transition ease-out duration-100',
          enterToClass: 'opacity-100',
          leaveClass: 'transition ease-in opacity-100',
          leaveActiveClass: '',
          leaveToClass: 'opacity-0 duration-75'
        },
      }
    }
  },
  't-pagination': {
    component: TPagination,
    props: {
      classes: {
        wrapper: 'inline-flex items-center justify-center text-center bg-white mx-auto rounded',
        element: 'w-8 h-8 border-l border-back first:border-0',
        activeElement: 'w-8 h-8 border-l border-back first:border-0',
        disabledElement: 'w-8 h-8 border-l border-back first:border-0 inline-flex items-center justify-center text-center',
        ellipsisElement: 'w-8 h-8 border-l border-back first:border-0 inline-flex items-center justify-center text-center',
        activeButton: 'bg-blue-500 w-full h-full text-white hover:bg-blue-600 transition duration-100 ease-in-out focus:ring-2 focus:ring-blue-500 focus:outline-none focus:ring-opacity-50',
        disabledButton: 'opacity-25 w-full h-full cursor-not-allowed transition duration-100 ease-in-out',
        button: 'hover:bg-back text-blue-900 w-full h-full transition duration-100 ease-in-out focus:ring-2 focus:ring-blue-500 focus:outline-none focus:ring-opacity-50',
        ellipsis: ''
      }
    }
  },
  't-table': {
    component: TTable,
    props: {
      classes: {
        theadTh: 'text-left',
      },
      variants: {
        centered: {
          theadTh: 'text-center',
          td: 'text-center'
        }
      }
    }
  },
  't-datepicker': {
    component: TDatepicker,
    props: {
      fixedClasses: {
        navigator: 'flex',
        navigatorViewButton: 'flex items-center',
        navigatorViewButtonIcon: 'flex-shrink-0 h-5 w-5',
        navigatorViewButtonBackIcon: 'flex-shrink-0 h-5 w-5',
        navigatorLabel: 'flex items-center py-1',
        navigatorPrevButtonIcon: 'h-6 w-6 inline-flex',
        navigatorNextButtonIcon: 'h-6 w-6 inline-flex',
        inputWrapper: 'relative',
        viewGroup: 'inline-flex flex-wrap',
        view: 'w-64',
        calendarDaysWrapper: 'grid grid-cols-7',
        calendarHeaderWrapper: 'grid grid-cols-7',
        monthWrapper: 'grid grid-cols-4',
        yearWrapper: 'grid grid-cols-4',
        input: 'disabled:opacity-50 disabled:cursor-not-allowed focus:outline-none transition duration-150 ease-in-out',
        clearButton: 'flex flex-shrink-0 items-center justify-center absolute right-0 top-0 m-2 h-6 w-6',
        clearButtonIcon: 'fill-current h-3 w-3'
      },
      classes: {
        wrapper: 'flex flex-col',
        dropdownWrapper: 'relative z-10',
        dropdown: 'origin-top-left absolute rounded shadow bg-white overflow-hidden mt-1',
        enterClass: 'opacity-0 scale-95',
        enterActiveClass: 'transition transform ease-out duration-100',
        enterToClass: 'opacity-100 scale-100',
        leaveClass: 'opacity-100 scale-100',
        leaveActiveClass: 'transition transform ease-in duration-75',
        leaveToClass: 'opacity-0 scale-95',
        inlineWrapper: '',
        inlineViews: 'rounded bg-white border mt-1 inline-flex',
        inputWrapper: '',
        input: 'pl-5 pr-7 h-10 block w-full rounded-lg text-gray-900 placeholder-blue-300 bg-white border border-blue-200 hover:border-blue-300 focus:border-blue-500',
        clearButton: 'hover:bg-gray-100 rounded transition duration-100 ease-in-out text-gray-600',
        clearButtonIcon: '',
        viewGroup: '',
        view: '',
        navigator: 'pt-2 px-3',
        navigatorViewButton: 'text-blue-600 transition ease-in-out duration-100 inline-flex cursor-pointer rounded-full px-2 py-1 -ml-1 hover:bg-gray-100',
        navigatorViewButtonIcon: 'fill-current text-gray-600',
        navigatorViewButtonBackIcon: 'fill-current text-gray-600',
        navigatorViewButtonMonth: 'text-gray-900 font-semibold',
        navigatorViewButtonYear: 'text-gray-700 ml-1',
        navigatorViewButtonYearRange: 'text-gray-700 ml-1',
        navigatorLabel: 'py-1',
        navigatorLabelMonth: 'text-gray-900 font-semibold',
        navigatorLabelYear: 'text-gray-700 ml-1',
        navigatorPrevButton: 'transition ease-in-out duration-100 inline-flex cursor-pointer hover:bg-gray-100 rounded-full p-1 ml-2 ml-auto disabled:opacity-50 disabled:cursor-not-allowed',
        navigatorNextButton: 'transition ease-in-out duration-100 inline-flex cursor-pointer hover:bg-gray-100 rounded-full p-1 -mr-1 disabled:opacity-50 disabled:cursor-not-allowed',
        navigatorPrevButtonIcon: 'text-gray-600',
        navigatorNextButtonIcon: 'text-gray-600',
        calendarWrapper: 'px-3 pt-2',
        calendarHeaderWrapper: '',
        calendarHeaderWeekDay: 'uppercase text-xs text-gray-700 w-8 h-8 flex items-center justify-center',
        calendarDaysWrapper: '',
        calendarDaysDayWrapper: 'w-full h-8 flex flex-shrink-0 items-center',
        otherMonthDay: 'text-sm rounded-full w-8 h-8 mx-auto hover:bg-blue-100 text-gray-600 disabled:opacity-50 disabled:cursor-not-allowed',
        emptyDay: '',
        inRangeFirstDay: 'text-sm bg-blue-500 text-white w-full h-8 rounded-l-full',
        inRangeLastDay: 'text-sm bg-blue-500 text-white w-full h-8 rounded-r-full',
        inRangeDay: 'text-sm bg-blue-200 w-full h-8 disabled:opacity-50 disabled:cursor-not-allowed',
        selectedDay: 'text-sm rounded-full w-8 h-8 mx-auto bg-blue-700 text-white disabled:opacity-50 disabled:cursor-not-allowed',
        activeDay: 'text-sm rounded-full bg-blue-100 w-8 h-8 mx-auto disabled:opacity-50 disabled:cursor-not-allowed',
        highlightedDay: 'text-sm rounded-full bg-blue-200 w-8 h-8 mx-auto disabled:opacity-50 disabled:cursor-not-allowed',
        day: 'text-black text-sm rounded-full w-8 h-8 mx-auto hover:bg-blue-100 disabled:opacity-50 disabled:cursor-not-allowed',
        today: 'text-blue-700 text-sm rounded-full w-8 h-8 mx-auto hover:bg-blue-100 disabled:opacity-50 disabled:cursor-not-allowed border border-blue-500',
        monthWrapper: 'px-3 pt-2',
        selectedMonth: 'text-sm rounded w-full h-12 mx-auto bg-blue-700 text-white',
        activeMonth: 'text-sm rounded w-full h-12 mx-auto bg-blue-100',
        month: 'text-blue-700 text-sm rounded w-full h-12 mx-auto hover:bg-blue-100',
        yearWrapper: 'px-3 pt-2',
        year: 'text-blue-700 text-sm rounded w-full h-12 mx-auto hover:bg-blue-100',
        selectedYear: 'text-sm rounded w-full h-12 mx-auto bg-blue-700 text-white',
        activeYear: 'text-sm rounded w-full h-12 mx-auto bg-blue-100'
      },
      variants: {
        error: {
          input: 'pl-5 pr-7 h-10 block w-full rounded-lg text-gray-900 placeholder-blue-300 bg-white border border-error',
          clearButton: 'hover:bg-red-200 text-error'
        },
        hidden: {
          input: 'border-none focus:border-none text-blue-900 text-sm w-px h-px p-0 text-transparent bg-error',
          dropdown: 'origin-top-right right-0 absolute rounded shadow bg-white overflow-hidden mt-1',
          clearButton: 'hidden',
        },
        top: {
          dropdown: 'origin-bottom-left bottom-0 absolute rounded shadow bg-white overflow-hidden mb-2',
        },
      }
    }
  },
  't-checkbox': {
    component: TCheckbox,
    props: {
      wrapped: true,
      fixedClasses: {
        input: 'text-blue-900 transition duration-100 ease-in-out border-gray-400 shadow-sm focus:border-blue-900 focus:ring-2 focus:ring-blue-900 focus:ring-opacity-50 focus:ring-offset-0 disabled:opacity-50 disabled:cursor-not-allowed appearance-none cursor-pointer',
      },
      classes: {
        label: 'mx-2 text-gray-900 select-none',
        input: 'rounded-sm',
        inputWrapper: 'inline-flex',
        wrapper: 'flex items-center',
        // labelChecked: '',
        // inputWrapperChecked: '',
        // wrapperChecked: '',
      },
      variants: {
        md: {
          input: 'w-5 h-5 rounded',
        },
      },
    }
  },
  't-toggle': {
    component: TToggle,
    props: {
      fixedClasses: {
        wrapper: 'relative inline-flex flex-shrink-0 cursor-pointer transition-colors ease-in-out duration-200',
        wrapperChecked: 'relative inline-flex flex-shrink-0 cursor-pointer transition-colors ease-in-out duration-200  border-2 border-transparent focus:ring-2 focus:ring-blue-500 focus:outline-none focus:ring-opacity-50',
        wrapperDisabled: 'relative inline-flex flex-shrink-0 cursor-pointer transition-colors ease-in-out duration-200 opacity-50 cursor-not-allowed',
        wrapperCheckedDisabled: 'relative inline-flex flex-shrink-0 cursor-pointer transition-colors ease-in-out duration-200 opacity-50 cursor-not-allowed border-2 border-transparent focus:ring-2 focus:ring-blue-500 focus:outline-none focus:ring-opacity-50',
        button: 'inline-block absolute transform translate-x-0 transition ease-in-out duration-200',
        buttonChecked: 'inline-block absolute transform translate-x-full transition ease-in-out duration-200',
        checkedPlaceholder: 'inline-block',
        uncheckedPlaceholder: 'inline-block'
      },
      classes: {
        wrapper: 'bg-blue-500 rounded-full border-2 border-transparent focus:ring-2 focus:ring-blue-500 focus:outline-none focus:ring-opacity-50',
        wrapperChecked: 'bg-blue-900 rounded-full',
        wrapperDisabled: 'bg-gray-100 rounded-full border-2 border-transparent focus:ring-2 focus:ring-blue-500 focus:outline-none focus:ring-opacity-50',
        wrapperCheckedDisabled: 'bg-blue-900 rounded-full border-2 border-transparent focus:ring-2 focus:ring-blue-500 focus:outline-none focus:ring-opacity-50',
        button: 'h-5 w-5 rounded-full bg-white shadow flex items-center justify-center text-gray-400 text-xs',
        buttonChecked: 'h-5 w-5 rounded-full bg-white shadow flex items-center justify-center text-blue-500 text-xs',
        checkedPlaceholder: 'rounded-full w-5 h-5 flex items-center justify-center text-gray-400 text-xs',
        uncheckedPlaceholder: 'rounded-full w-5 h-5 flex items-center justify-center text-gray-400 text-xs'
      },
      variants: {
        danger: {
          wrapperChecked: 'bg-red-500 rounded-full',
          wrapperCheckedDisabled: 'bg-red-500 rounded-full'
        },
        success: {
          wrapperChecked: 'bg-green-500 rounded-full',
          wrapperCheckedDisabled: 'bg-green-500 rounded-full'
        },
        box: {
          wrapper: 'bg-gray-100 rounded-sm border-2 border-transparent focus:ring-2 focus:ring-blue-500 focus:outline-none focus:ring-opacity-50',
          wrapperChecked: 'bg-blue-500 rounded-sm',
          wrapperCheckedDisabled: 'bg-blue-500 rounded-sm',
          button: 'h-6 w-6 rounded-sm bg-white shadow flex items-center justify-center text-gray-400 text-xs',
          buttonChecked: 'h-6 w-6 rounded-sm  bg-white shadow flex items-center justify-center text-blue-500 text-xs',
          checkedPlaceholder: 'rounded-sm w-6 h-6 flex items-center justify-center text-gray-400 text-xs',
          uncheckedPlaceholder: 'rounded-sm w-6 h-6 flex items-center justify-center text-gray-400 text-xs'
        }
      }
    }
  },
}

Vue.use(VueTailwind, settings)
