import Roles from '~/constants/roles';

export const state = () => ({
  activeOrganizationId: false,
  activeRole: null,
  organizations: [],
})

export const actions = {
  async loadOrganizations ({ commit }) {
    try {
      const { data: { data } } = await this.$axios.get('organizations')
      commit('SET_ORGANIZATIONS', data)
      return Promise.resolve(data)
    } catch (e) {
      return Promise.reject(e)
    }
  },
  setOrganization ({ commit, state }, payload) {
    const organization = state.organizations.find(organization => organization.id === payload)
    if (typeof organization !== 'undefined') {
      commit('SET_ORGANIZATION', payload)
      this.$auth.$storage.setLocalStorage('context', organization.id)
      return Promise.resolve(organization)
    } else {
      return Promise.reject(new Error(`ORGANIZATION_DOES_NOT_EXIST: ${payload}`))
    }
  },
  setRole ({ commit, state }, role) {
    if (Roles.includes(role)) {
      commit('SET_ROLE', role)
      this.$auth.$storage.setLocalStorage('context', `role:${role}`)
      return Promise.resolve(role)
    } else {
      return Promise.reject(new Error('ROLE_DOES_NOT_EXIST'))
    }
  },
  presetContext ({ state, commit, dispatch }, organizationId, role) {
    const context = this.$auth.$storage.getLocalStorage('context')

    // Set from url params etc.
    if (typeof organizationId !== 'undefined' && organizationId !== null) {
      dispatch('setOrganization', organizationId);
    } else if (typeof role !== 'undefined' && role !== null) {
      dispatch('setRole', role);
    } else if (context) { // Set from local storage context
      if (context.indexOf('role:') === 0) {
        const role = context.substr(5)
        dispatch('setRole', role);
        return Promise.resolve();
      } else {
        if (state.organizations.find(organization => organization.id === context)) {
          const organization = dispatch('setOrganization', context)
          return Promise.resolve(organization)
        } else {
          dispatch('resetContext')
        }
      }
    } else if (state.organizations.length) { // By default set to first organization from list
      dispatch('setOrganization', state.organizations[0].id)
    } else if (this.$auth.$state.user.roles.length > 0) {
      dispatch('setRole', this.$auth.$state.user.roles[0])
    } else {
      // @todo: report to sentry
    }
  },
  resetContext ({ commit }) {
    this.$auth.$storage.removeLocalStorage('context')
    commit('CLEAR_ACTIVE')
    commit('CLEAR_ORGANIZATIONS')
  },
  clearActiveContext ({ commit }) {
    this.$auth.$storage.removeLocalStorage('context')
    commit('CLEAR_ACTIVE')
    return Promise.resolve()
  }
}

export const mutations = {
  SET_ORGANIZATIONS: (state, payload) => {
    state.organizations = payload
  },
  SET_ORGANIZATION: (state, payload) => {
    state.activeOrganizationId = payload
  },
  SET_ROLE: (state, role) => {
    state.activeRole = role
  },
  CLEAR_ACTIVE: (state) => {
    state.activeOrganizationId = false
    state.activeRole = null
  },
  CLEAR_ORGANIZATIONS: (state) => {
    state.organizations = []
  }
}

export const getters = {
  organizations: state => state.organizations,
  organization: (state) => {
    const organization = state.organizations.find(organization => organization.id === state.activeOrganizationId)

    return typeof organization !== 'undefined' ? organization : false;
  },
  activeOrganizationId: state => state.activeOrganizationId,
  role: state => state.activeRole
}
