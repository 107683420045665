//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  methods: {
    logout() {
      if (this.$auth.loggedIn) {
        this.$auth.logout();
      } else {
        this.$router.push('/');
      }
    },
  },
}
