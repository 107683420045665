export const STATUS_INVITED = 'invited';
export const STATUS_TEST_NOT_COMPLETED = 'test_not_completed';
export const STATUS_TEST_COMPLETED = 'test_completed';
export const STATUS_REPORT_CREATED = 'report_created';
export const STATUS_REPORT_UNLOCKED = 'report_unlocked';

let LABEL_STYLES_TEMP = {}
LABEL_STYLES_TEMP[STATUS_INVITED] = 'bg-gray-200 text-black';
LABEL_STYLES_TEMP[STATUS_TEST_NOT_COMPLETED] = 'bg-purple-100 text-purple-500';
LABEL_STYLES_TEMP[STATUS_TEST_COMPLETED] = 'bg-green-100 text-green-500';
LABEL_STYLES_TEMP[STATUS_REPORT_CREATED] = 'bg-blue-100 text-blue-900';
LABEL_STYLES_TEMP[STATUS_REPORT_UNLOCKED] = 'bg-gray-200 text-blue-900';
export const LABEL_STYLES = LABEL_STYLES_TEMP
