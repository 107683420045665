//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  props: {
    value: {},
    fetchUrl: {
      type: String,
      required: true,
    },
    selectedItemUrl: {
      type: String,
      required: false,
    },
    selectedItem: {
      type: Object,
      required: false,
    },
    valueAttribute: {
      type: String,
      required: true,
    },
    textAttribute: {
      type: String,
      required: true,
    },
    minimumInputLength: {
      type: Number,
      required: false,
      default: () => 1,
    },
  },

  data() {
    return {
      options: null,
    }
  },

  computed: {
    valueComputed: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('update:value', value);
      },
    },
  },

  methods: {
    fetchOptions(search, page) {
      if (typeof page === 'undefined' || page === null) {
        page = 1;
      }

      return this.$axios.get(this.fetchUrl, {
        params: {
          filters: {
            search: search,
          },
          page: {
            number: page,
          },
        }
      }).then((response) => {
        return {
          results: response.data.data,
          hasMorePages: response.data.meta.pagination.total_pages > page,
        };
      })
    },

    async loadPlaceHolderOption() {
      if (typeof this.selectedItem !== 'undefined' && this.selectedItem !== null) {
        this.options = [{
          id: this.selectedItem[this.valueAttribute],
          name: this.selectedItem[this.textAttribute],
        }];
      } else if (typeof this.value !== 'undefined' && this.value !== null) {
        const item = (await this.$axios.get(this.selectedItemUrl)).data;

        this.options = [{
          id: item[this.valueAttribute],
          name: item[this.textAttribute],
        }];
      }
    },

    minimumInputLengthText() {
      return this.$t('other.minimum_input_length_text').replaceAll(':number', this.minimumInputLength)
    },
  },

  mounted () {
    this.loadPlaceHolderOption();
  }
}
