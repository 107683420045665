import Vue from "vue";
import VueTippy, { TippyComponent } from "vue-tippy";

Vue.use(VueTippy, {
  arrow: true,
});

Vue.component("tippy", TippyComponent);

import "tippy.js/themes/light-border.css";
