import {
  LABEL_STYLES,
  STATUS_REPORT_CREATED,
  STATUS_REPORT_UNLOCKED,
  STATUS_TEST_COMPLETED
} from '~/constants/client-statuses'
import Characteristics from '~/constants/characteristics'

export default {
  methods: {
    shallowEqual(object1, object2) {
      const keys1 = Object.keys(object1);
      const keys2 = Object.keys(object2);

      if (keys1.length !== keys2.length) {
        return false;
      }

      for (let key of keys1) {
        if (object1[key] !== object2[key]) {
          return false;
        }
      }

      return true;
    },

    /**
     * Initiates download in a browser. To get blob do axios request with responseType: 'blob'.
     */
    downloadBlob(blob, filename) {
      var fileURL = window.URL.createObjectURL(new Blob([blob]));
      var fileLink = document.createElement('a');

      fileLink.href = fileURL;
      fileLink.setAttribute('download', filename);
      document.body.appendChild(fileLink);

      fileLink.click();
    },

    slugToTitle(slug) {
      let title = slug
        .replaceAll('_', ' ')
        .replaceAll('-', ' ')
        .toLowerCase();


      return title.charAt(0).toUpperCase() + title.slice(1);
    },

    fallbackCopyTextToClipboard(text) {
      var textArea = document.createElement("textarea");
      textArea.value = text;

      // Avoid scrolling to bottom
      textArea.style.top = "0";
      textArea.style.left = "0";
      textArea.style.position = "fixed";

      document.body.appendChild(textArea);
      textArea.focus();
      textArea.select();

      try {
        document.execCommand('copy');
      } catch (err) {
        console.error('Fallback: Oops, unable to copy', err);
      }

      document.body.removeChild(textArea);
    },

    copyTextToClipboard(text) {
      if (!navigator.clipboard) {
        this.fallbackCopyTextToClipboard(text);
        return;
      }

      navigator.clipboard.writeText(text);
    },

    statusLabelStyles(status) {
      let styles = LABEL_STYLES[status] ?? '';

      if ([STATUS_TEST_COMPLETED, STATUS_REPORT_CREATED, STATUS_REPORT_UNLOCKED].includes(status)) {
        styles += ' cursor-pointer';
      }

      return styles;
    },

    statusLabelAction (status, testId) {
      switch (status) {
        case STATUS_TEST_COMPLETED:
          this.$refs.createReportDrawer.open(testId)
          break;

        case STATUS_REPORT_CREATED:
          this.$router.push(`/organizations/${this.contextOrganization.id}/tests/${testId}/live-assessment`);
          break;

        case STATUS_REPORT_UNLOCKED:
          this.$router.push(`/organizations/${this.contextOrganization.id}/tests/${testId}/report/profile`);
          break;
      }
    },

    countNestedObjectLength(obj) {
      let count = 0;

      Object.keys(obj).forEach((key) => {
        if (typeof obj[key] === 'object' && obj[key] !== null) {
          count += this.countNestedObjectLength(obj[key]);
        } else {
          count += 1;
        }
      })

      return count;
    },

    /**
     * Sorts object by standard characteristics order. Helps to make sure correct and aligned data is shown in charts.
     * @param object
     * @returns object
     */
    sortObjectByCharacteristics(object) {
      let tmpObject = {};

      Characteristics.all.forEach(characteristic => {
        if (typeof object[characteristic] !== 'undefined') {
          tmpObject[characteristic] = object[characteristic];
        }
      });

      return tmpObject;
    },

    /**
     * Allows to enter only integer or decimal number.
     * Also allows to enter number with a dot at the end as it is checked at a single keypress and
     * otherwise would not allow to enter a decimal number.
     * @param e
     */
    onlyNumericInput (e) {
      const char = String.fromCharCode(e.keyCode);

      if (!(/^[0-9\.]+$/.test(char))) {
        e.preventDefault()
      }
    },
  },
}
