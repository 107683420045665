//
//
//
//
//
//

export default {
  computed: {
    language: {
      get() {
        return this.$i18n.locale;
      },
      set(lang) {
        this.$i18n.setLocale(lang).then(() => {
          this.$nuxt.refresh();
          this.$router.app.refresh();
        });
      },
    },
    languageOptions() {
      return this.$i18n.locales.map(locale => {
        return {
          value: locale.code,
          text: this.$t(`languages.${locale.code}`),
        };
      })
    },
  },
}
