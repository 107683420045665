//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters, mapActions } from 'vuex'

export default {
  name: 'NavigationUser',
  data: () => ({
    isOpen: false
  }),
  computed: {
    ...mapGetters({
      organizations: 'context/organizations',
      organization: 'context/organization'
    })
  },
  methods: {
    ...mapActions({
      setOrganization: 'context/setOrganization',
      resetContext: 'context/resetContext',
      clearActiveContext: 'context/clearActiveContext',
    }),
    toggle () {
      this.isOpen = !this.isOpen
    },
    hide () {
      this.isOpen = false
    },
    outside () {
      console.log('outside')
    }
  }
}
