//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters, mapActions } from 'vuex'
import TermsAndConditionsModal from '~/components/TermsAndConditionsModal'

export default {
  middleware: ['auth'],
  computed: {
    ...mapGetters({
      organizations: 'context/organizations',
      organization: 'context/organization'
    })
  },
  watch:{
    $route (to, from) {
      this.$refs.userDropdown.doHide()
      this.$refs.navigationUser?.hide()
    }
  },
  components: {
    TermsAndConditionsModal,
  },
  methods: {
    ...mapActions({
      setOrganization: 'context/setOrganization',
      resetContext: 'context/resetContext',
      clearActiveContext: 'context/clearActiveContext',
    }),
    async logout () {
      await this.$auth.logout()
      this.$router.replace('/login')
      this.resetContext()
    },
  }
}
