export default function ({ $axios, redirect, $auth }) {
  $axios.onError(error => {
    if (
      typeof error.response !== 'undefined'
      && parseInt(error.response.status) === 401
      && typeof error.response.headers['auth-error'] !== 'undefined'
      && error.response.headers['auth-error'] === 'blocked'
    ) {
      $auth.logout();

      redirect('/login');
    } else {
      return Promise.reject(error)
    }
  })
}
