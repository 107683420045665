import Qs from 'qs'

export default ({ app: { $axios, i18n } }) => {
  $axios.interceptors.request.use((config) => {
    config.paramsSerializer = (params) => {
      return Qs.stringify(params, {
        arrayFormat: 'brackets',
        encode: false
      })
    }
    return config
  }, function (error) {
    return Promise.reject(error)
  })
}
