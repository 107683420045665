import moment from 'moment'

export default {
  methods: {
    formatDateTime(date) {
      return moment(date).format('YYYY.MM.DD HH:mm')
    },
    formatDate(date) {
      return moment(date).format('YYYY.MM.DD')
    },
    formatTimezoneForAPI(timezone) {
      if (timezone === 'Asia/Calcutta') {
        timezone = 'Asia/Kolkata';
      }

      return timezone;
    },
  }
}
