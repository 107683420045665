export default {
  methods: {
    async openConfirmationModal(text, onConfirm) {
      await this.$store.dispatch('confirmation-modal/open', { text, onConfirm })
    },

    async closeConfirmationModal() {
      await this.$store.dispatch('confirmation-modal/close')
    },

    async confirmConfirmationModal() {
      const handler = this.$store.getters['confirmation-modal/onConfirm'];

      await this.closeConfirmationModal();

      handler();
    },
  }
}
