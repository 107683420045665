export const state = () => ({
  show: false,
  text: null,
  onConfirm: null,
})

export const actions = {
  open ({ commit }, payload) {
    commit('SET_TEXT', payload.text)
    commit('SET_SHOW', true)
    commit('SET_ON_CONFIRM', payload.onConfirm)
  },
  close ({ commit }) {
    commit('SET_SHOW', false)
    commit('SET_TEXT', null)
    commit('SET_ON_CONFIRM', null)
  },
}

export const mutations = {
  SET_SHOW: (state, payload) => {
    state.show = payload
  },
  SET_TEXT: (state, payload) => {
    state.text = payload
  },
  SET_ON_CONFIRM: (state, payload) => {
    state.onConfirm = payload
  },
}
export const getters = {
  isOpen: state => state.show,
  text: state => state.text,
  onConfirm: state => state.onConfirm,
}
