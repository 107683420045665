export default async function ({ from, route, error, redirect, app: { $auth, store } }) {
  let isRoleGranted = true
  let isOrganizationGranted = true
  let isOrganizationRoleGranted = true
  let isOrganizationTypeGranted = true

  const meta = route.meta.find(meta => meta);

  if (typeof meta === 'undefined') {
    return;
  }

  const routeAccess = meta.access
  const userAccess = {
    roles: $auth.loggedIn ? $auth.user.roles : [],
    organization: store.getters['context/organization']
  }

  if (routeAccess) {
    if (typeof routeAccess.user_role !== 'undefined') {
      isRoleGranted = userAccess.roles.includes(routeAccess.user_role)
    }

    if (typeof routeAccess.organization_role !== 'undefined') {
      isOrganizationRoleGranted = userAccess.organization.user_role === routeAccess.organization_role
      routeAccess.organization_required = true
    }

    if (typeof routeAccess.organization_type !== 'undefined') {
      isOrganizationTypeGranted = userAccess.organization.type === routeAccess.organization_type
      routeAccess.organization_required = true
    }

    // Set current organization to state
    if (typeof routeAccess.organization_required !== 'undefined' && routeAccess.organization_required) {
      if (userAccess.organization) {
        if (userAccess.organization.id !== route.params.id) {
          const organization = store.getters['context/organizations'].find(organization => organization.id === route.params.id)
          if (organization) {
            await store.dispatch('context/setOrganization', route.params.id)
          } else {
            isOrganizationGranted = false
          }
        }
      } else {
        isOrganizationGranted = false
      }
    }

    if (typeof routeAccess.user_role !== 'undefined') {
      await store.dispatch('context/setRole', routeAccess.user_role)
    }

    if (!isRoleGranted || !isOrganizationGranted || !isOrganizationRoleGranted || !isOrganizationTypeGranted) {
      console.error('Access denied');

      if (from.path !== route.path) {
        return redirect(from)
      } else {
        return redirect('/')
      }
    }
  }
}
