export default ({ $auth }, inject) => {
  const permissions = {
    can: (key, contextOrganization) => {
      if (!$auth.loggedIn) {
        return false;
      }

      if (typeof $auth.user.can === 'undefined') {
        return false;
      }

      return $auth.user.can[key] && (typeof contextOrganization === 'undefined' ? true : contextOrganization.user_can_do_validations);
    },
  }

  inject('permissions', permissions);
}
