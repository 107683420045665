import Vue from "vue"

export default async function ({ $auth, $sentry }) {
  const setContextFn = () => {
    const email = $auth?.user?.email ?? null;
    const name = $auth?.user?.name ?? null;
    const loggedIn = $auth.loggedIn;

    $sentry.setContext('user data', {
      name: name,
      email: email,
      logged_in: loggedIn ? 'Yes' : 'No',
    })
    $sentry.setTags({
      user_email: email,
      user_name: name,
      user_logged_in: loggedIn ? 'Yes' : 'No',
    })
  };

  setContextFn();

  if (!Vue.__sentry_context_mixin__) {
    Vue.__sentry_context_mixin__ = true
    Vue.mixin({
      watch: {
        '$auth.user'() {
          setContextFn();
        },
      },
    })
  }
}
