export default async function ({ $auth, store, app }) {
  if ($auth.loggedIn) {
    let organizationId = undefined;
    let role = undefined;

    if (app.context.route.path.substr(0, "/organizations".length) === "/organizations") {
      organizationId = app.context.route.params.id;
    } else if (app.context.route.path.substr(0, "/super-admin".length) === "/super-admin") {
      role = 'super_admin';
    } else if (app.context.route.path.substr(0, "/client".length) === "/client") {
      role = 'client';
    }

    await store.dispatch('context/loadOrganizations')
    await store.dispatch('context/presetContext', organizationId, role)
  }
}
