//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  data() {
    return {
      accepted: false,
      saving: false,
    }
  },

  computed: {
    showModal() {
      if (!this.$auth.loggedIn || this.$auth.user.terms_accepted) {
        return false;
      }

      // If user has only client role and no organizations
      if (
        this.$auth.user.roles.length === 1
        && this.$auth.user.roles[0] === 'client'
        && !this.$auth.user.belongs_to_organizations
      ) {
        return false;
      }

      return true;
    },
  },

  methods: {
    decline() {
      this.$auth.logout();
    },

    async accept() {
      if (this.saving) {
        return;
      }

      this.saving = true;

      await this.$axios.post('/accept-terms');

      await this.$auth.fetchUser();

      this.saving = false;

      this.accepted = false;
    },
  },
}
