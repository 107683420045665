//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters } from 'vuex'
import LanguageSelector from '~/components/LanguageSelector'

export default {
  name: 'Navigation',
  components: { LanguageSelector },
  computed: {
    ...mapGetters({
      organization: 'context/organization',
      role: 'context/role',
    })
  },
  fetch () {
    this.setNavigationState()
    window.addEventListener('resize', this.setNavigationState)
  },
  data: () => ({
    width: 0,
    visible: true
  }),
  created () {
    this.$eventBus.$on('navigation:show', this.show)
    this.$eventBus.$on('navigation:hide', this.hide)
    this.$eventBus.$on('navigation:toggle', this.toggle)
  },
  destroyed () {
    this.$eventBus.$off('navigation:show')
    this.$eventBus.$off('navigation:hide')
    this.$eventBus.$off('navigation:toggle')
    window.removeEventListener('resize', this.setNavigationState)
  },
  methods: {
    hide () {
      this.visible = false
    },
    show () {
      this.visible = true
    },
    toggle () {
      this.visible = !this.visible
    },
    setNavigationState () {
      if (window.innerWidth >= 768) {
        this.show()
      } else {
        this.hide()
      }
    },
    linkClickHandler () {
      if (window.innerWidth < 768) {
        this.hide()
      }
    }
  }
}
