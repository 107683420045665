const INTELLIGENCES = [
  'bodily_gross',
  'bodily_fine',
  'interpersonal',
  'logical',
  'linguistic',
  'visual_graphic',
  'visual_spatial',
  'musical',
  'intrapersonal',
  'naturalistic',
];

const NATURES = [
  'protective',
  'educative',
  'administrative',
  'creative',
  'healing',
  'entertaining',
  'providing',
  'entrepreneurial',
  'adventurous',
];

export default {
  intelligences: INTELLIGENCES,
  natures: NATURES,
  all : [...INTELLIGENCES, ...NATURES],
}
