import Vue from 'vue'
import DrawerTextValue from '~/components/ui/DrawerTextValue'
import DropdownButton from '~/components/ui/DropdownButton'
import SortToggle from '~/components/ui/SortToggle'
import ConfirmationModal from '~/components/ui/ConfirmationModal'
import PaginatedSelect from '~/components/ui/PaginatedSelect'
import TableResponsive from '~/components/ui/TableResponsive'

if (!Vue.__vue_components__) {
  Vue.__vue_components__ = true

  Vue.component('drawer-text-value', DrawerTextValue);
  Vue.component('dropdown-button', DropdownButton);
  Vue.component('sort-toggle', SortToggle);
  Vue.component('confirmation-modal', ConfirmationModal);
  Vue.component('paginated-select', PaginatedSelect);
  Vue.component('table-responsive', TableResponsive);
}
