//
//
//
//
//
//
//

export default {
  props: {
    sort: {
      required: true,
      type: String,
    },
    name: {
      required: true,
      type: String,
    }
  },
}
