import moment from 'moment'

export default {
  methods: {
    formatPrice(price, currency) {
      const finalPrice = Math.round(price * currency.rate * 100) / 100;

      switch (currency.key) {
        case ('eur'):
          return finalPrice + '€';
        case ('usd'):
          return '$' + finalPrice;
      }
    },
  }
}
