//
//
//
//
//
//
//
//
//

export default {
  props: ["title"],
}
