import Vue from "vue"
import ErrorsMixin from '~/mixins/ErrorsMixin'
import DateMixin from '~/mixins/DateMixin'
import CurrencyMixin from '~/mixins/CurrencyMixin'
import HelpersMixin from '~/mixins/HelpersMixin'
import ConfirmationModalMixin from '~/mixins/ConfirmationModalMixin'

if (!Vue.__errors_mixin__) {
  Vue.__errors_mixin__ = true
  Vue.mixin(ErrorsMixin)
}

if (!Vue.__date_mixin__) {
  Vue.__date_mixin__ = true
  Vue.mixin(DateMixin)
}

if (!Vue.__currency_mixin__) {
  Vue.__currency_mixin__ = true
  Vue.mixin(CurrencyMixin)
}

if (!Vue.__helpers_mixin__) {
  Vue.__helpers_mixin__ = true
  Vue.mixin(HelpersMixin)
}

if (!Vue.__confirmation_modal_mixin__) {
  Vue.__confirmation_modal_mixin__ = true
  Vue.mixin(ConfirmationModalMixin)
}
