import organizationCareerSets from '~/api/organization/career-sets.js'
import organizationSettings from '~/api/organization/admin/settings.js'

export default (ctx, inject) => {
  const api = {
    organization: {
      admin: {
        settings: organizationSettings(ctx.$axios),
      },
      careerSets: organizationCareerSets(ctx.$axios),
    },
  }

  inject('api', api)
}
