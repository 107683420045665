const LOCALE_WAS_SET_STORAGE_KEY = 'locale_was_set';

export default ({ app: { $axios, i18n } }) => {
  // Add language header to every axios request
  $axios.interceptors.request.use((config) => {
    config.headers['App-Language'] = i18n.locale

    return config
  }, function (error) {
    return Promise.reject(error)
  })

  // Try to set nuxt locale based on http request response from server
  $axios.interceptors.response.use(async (response) => {
    const lang = response.headers['recommended-language'] ?? null;

    const languageCanBeSet = window.localStorage.getItem(LOCALE_WAS_SET_STORAGE_KEY) === null;

    if (lang !== null && lang.length > 0 && languageCanBeSet) {
      await i18n.setLocale(lang);

      window.localStorage.setItem(LOCALE_WAS_SET_STORAGE_KEY, 1);
    }

    return response;
  });
}
